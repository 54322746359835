<template>
  <div class="direct-flight-select mb-1 justify-content-between ml-md-1">
    <b-form-checkbox
      v-model="isChecked"
      switch
      :disabled="isDisabledGroupedItinerary"
      class="custom-control-warning"
      @change="val => handleUpdate(val)"
    >
      <span :class="`text-nowrap text-body font-weight-bold cursor-pointer`">{{ $t('flight.isSplitTripFare') }}</span>
    </b-form-checkbox>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import {
  ref,
  toRefs,
  watch,
} from '@vue/composition-api'

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    isGroupedItineraryResponse: {
      type: Boolean,
      required: true,
    },
    isDisabledGroupedItinerary: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { isGroupedItineraryResponse } = toRefs(props)
    const isChecked = ref(!isGroupedItineraryResponse.value)

    watch(() => isGroupedItineraryResponse.value, newVal => {
      if (newVal === isChecked.value) {
        isChecked.value = !newVal
      }
    }, { immediate: true })

    function handleUpdate(val) {
      emit('update:isGroupedItineraryResponse', !val)
    }

    return {
      isChecked,
      handleUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.direct-flight-select {
  margin-bottom: -7px !important;
}
</style>
